<template>
	<v-container fluid class="pt-2 pb-2 blue-grey lighten-5">
		<template v-if="!pageLoading">
			<v-row v-if="showDashboard">
				<v-col md="12">
					<DashboardHeader
						@scrollIntoView="scrollIntoView"
						v-if="dashboardDetail"
						:dashboardDetail="dashboardDetail"
					>
					</DashboardHeader>
				</v-col>

				<v-col md="6" v-if="getPermission('quotation::view')">
					<QuotationStatus class="mb-2" v-if="dashboardDetail" :dashboardDetail="dashboardDetail" />
				</v-col>
				<v-col md="6" v-if="getPermission('purchase-order::view')">
					<PurchaseOrderStatus v-if="dashboardDetail" :dashboardDetail="dashboardDetail" />
				</v-col>
				<v-col md="6" v-if="getPermission('task::view')">
					<TaskStatus ref="TaskStatus" v-if="dashboardDetail" :dashboardDetail="dashboardDetail" />
				</v-col>
				<v-col md="6" v-if="getPermission('project::view')">
					<ProjectStatus v-if="dashboardDetail" :dashboardDetail="dashboardDetail"
				/></v-col>
				<v-col md="12" v-if="getPermission('task::view')">
					<div id="task">
						<DashboardTaskStatusAndListing v-if="dashboardDetail" :dashboardDetail="dashboardDetail" />
					</div>
				</v-col>
				<v-col md="12" v-if="getPermission('claim-expense::view')">
					<ExpenseStats v-if="dashboardDetail" :dashboardDetail="dashboardDetail" />
				</v-col>
				<v-col md="12" v-if="getPermission('quotation::view')">
					<DashboardQuotationListing />
				</v-col>
				<v-col md="12" v-if="getPermission('purchase-order::view')">
					<DashboardPurchaseOrderListing />
				</v-col>
				<v-col md="12" v-if="getPermission('project::view')">
					<DashboardProjectListing />
				</v-col>
				<!-- <v-col md="12" v-if="getPermission('task::view')">
					<div id="task">
						<TasKListingDashboard />
					</div>
				</v-col> -->
			</v-row>
			<v-col v-else md="12">
				<p class="font-weight-bolder text-center font-size-h4 font-size-h1-lg">
					Welcome to {{ $APPNAME }}
				</p>
			</v-col>
		</template>
		<template v-else>
			<div class="mx-20 my-40 text-center h-100">
				<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
			</div>
		</template>
	</v-container>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
// import DatePicker from "@/view/components/DateRangePicker";
// import SelectInput from "@/view/components/SelectInput";
// import ShowValue from "@/view/components/ShowValue";
// import Chip from "@/view/components/Chip";
import DashboardHeader from "@/view/module/dashboard/DashboardHeader.vue";
import DashboardProjectListing from "@/view/module/dashboard/DashboardProjectListing.vue";
import DashboardQuotationListing from "@/view/module/dashboard/DashboardQuotationListing.vue";
// import ProjectStats from "@/view/module/dashboard/ProjectStats.vue";
import QuotationStatus from "@/view/module/dashboard/QuotationStatus.vue";
// import TasKListingDashboard from "@/view/module/dashboard/TaskListingDashboard.vue";
import PurchaseOrderStatus from "@/view/module/dashboard/PurchaseOrderStatus.vue";
// import TaskStats from "@/view/module/dashboard/TaskStats.vue";
import ProjectStatus from "@/view/module/dashboard/ProjectStatus.vue";
// import DashboardTaskList from "@/view/module/dashboard/DashboardTaskList.vue";
import TaskStatus from "@/view/module/dashboard/TaskStatus.vue";
import DashboardTaskStatusAndListing from "@/view/module/dashboard/DashboardTaskStatusAndListing.vue";

import DashboardPurchaseOrderListing from "@/view/module/dashboard/DashboardPurchaseOrderListing.vue";
import ApiService from "@/core/services/api.service";
import ObjectPath from "object-path";
// import TodayStatus from "../module/dashboard/TodayStatus.vue";
import ExpenseStats from "../module/dashboard/ExpenseStats.vue";

export default {
	ame: "dashboard",
	title: "Dashboard",
	components: {
		// Chip,
		// ShowValue,
		// DatePicker,
		// SelectInput
		DashboardQuotationListing,
		DashboardHeader,
		DashboardTaskStatusAndListing,
		DashboardProjectListing,
		// TaskStats,
		PurchaseOrderStatus,
		DashboardPurchaseOrderListing,
		// QuotationStats,
		QuotationStatus,
		// DashboardTaskList,
		// TodayStatus,
		ProjectStatus,
		// TasKListingDashboard,
		TaskStatus,
		ExpenseStats,
	},
	data() {
		return {
			pageLoading: true,
			detail_uuid: null,
			dashboardDetail: null,
			detail_dialog: false,
			months: [
				{ value: "january", text: "January" },
				{ value: "february", text: "February" },
				{ value: "march", text: "March" },
				{ value: "april", text: "April" },
				{ value: "may", text: "May" },
				{ value: "june", text: "June" },
				{ value: "july", text: "July" },
				{ value: "august", text: "August" },
				{ value: "september", text: "September" },
				{ value: "october", text: "October" },
				{ value: "november", text: "November" },
				{ value: "december", text: "December" },
			],
			filter_one: {
				month: null,
				date: [],
			},
			filter_two: {
				month: null,
				date: [],
			},
			filter_three: {
				month: null,
				date: [],
			},
			showDashboard: true,
			assetLoading: false,
			assets: {
				rows: [],
				current_page: 1,
				showing_string: null,
				total_page: 0,
			},
			scheduledLoading: false,
			scheduled_services: {
				rows: [],
				current_page: 1,
				showing_string: null,
				total_page: 0,
			},
			completedLoading: false,
			completed_services: {
				rows: [],
				current_page: 1,
				showing_string: null,
				total_page: 0,
			},
			certificateLoading: false,
			certificates: {
				rows: [],
				current_page: 1,
				showing_string: null,
				total_page: 0,
			},
		};
	},
	methods: {
		go_asset_detail({ uuid }) {
			this.$router.push({
				name: "asset-detail",
				params: { uuid },
				query: { t: new Date().getTime() },
			});
		},
		scrollIntoView() {
			const element = document.getElementById("task");
			element.scrollIntoView();
		},
		go_service_detail({ uuid }) {
			this.detail_uuid = uuid;
			this.detail_dialog = true;
		},
		getPagination(type, page) {
			if (type == "asset") {
				this.assets.current_page = page;
				this.getAssets();
			}

			if (type == "service-scheduled") {
				this.scheduled_services.current_page = page;
				this.getScheduledServices();
			}

			if (type == "service-completed") {
				this.completed_services.current_page = page;
				this.getCompletedServices();
			}

			if (type == "certificates") {
				this.certificates.current_page = page;
				this.getCertificates();
			}
		},
		getAssets() {
			this.assetLoading = true;

			const query = {
				"date-range": this.filter_one.date,
				month: this.filter_one.month,
				page: this.assets.current_page,
			};

			this.assets.rows = [];
			this.assets.current_page = 1;
			this.assets.showing_string = null;
			this.assets.total_page = 0;

			ApiService.query(`dashboard/assets`, query)
				.then((output) => {
					this.assets.rows = ObjectPath.get(output, "data.tbody", []);
					this.assets.current_page = ObjectPath.get(output, "data.current_page", 1);
					this.assets.showing_string = ObjectPath.get(output, "data.showing_string", null);
					this.assets.total_page = ObjectPath.get(output, "data.total_page", 0);
				})
				.catch(({ response }) => {
					console.error({ response });
				})
				.finally(() => {
					this.assetLoading = false;
				});
		},
		getScheduledServices() {
			this.scheduledLoading = true;

			const query = {
				"date-range": this.filter_two.date,
				month: this.filter_two.month,
				page: this.scheduled_services.current_page,
			};

			this.scheduled_services.rows = [];
			this.scheduled_services.current_page = 1;
			this.scheduled_services.showing_string = null;
			this.scheduled_services.total_page = 0;

			ApiService.query(`dashboard/scheduled-services`, query)
				.then((output) => {
					this.scheduled_services.rows = ObjectPath.get(output, "data.tbody", []);
					this.scheduled_services.current_page = ObjectPath.get(output, "data.current_page", 1);
					this.scheduled_services.showing_string = ObjectPath.get(output, "data.showing_string", null);
					this.scheduled_services.total_page = ObjectPath.get(output, "data.total_page", 0);
				})
				.catch(({ response }) => {
					console.error({ response });
				})
				.finally(() => {
					this.scheduledLoading = false;
				});
		},
		getCompletedServices() {
			this.completedLoading = true;

			const query = {
				"date-range": this.filter_three.date,
				month: this.filter_three.month,
				page: this.completed_services.current_page,
			};

			this.completed_services.rows = [];
			this.completed_services.current_page = 1;
			this.completed_services.showing_string = null;
			this.completed_services.total_page = 0;

			ApiService.query(`dashboard/completed-services`, query)
				.then((output) => {
					this.completed_services.rows = ObjectPath.get(output, "data.tbody", []);
					this.completed_services.current_page = ObjectPath.get(output, "data.current_page", 1);
					this.completed_services.showing_string = ObjectPath.get(output, "data.showing_string", null);
					this.completed_services.total_page = ObjectPath.get(output, "data.total_page", 0);
				})
				.catch(({ response }) => {
					console.error({ response });
				})
				.finally(() => {
					this.completedLoading = false;
				});
		},
		getCertificates() {
			this.certificateLoading = true;

			const query = { page: this.certificates.current_page };

			this.certificates.rows = [];
			this.certificates.current_page = 1;
			this.certificates.showing_string = null;
			this.certificates.total_page = 0;

			ApiService.query(`dashboard/certificates`, query)
				.then((output) => {
					this.certificates.rows = ObjectPath.get(output, "data.tbody", []);
					this.certificates.current_page = ObjectPath.get(output, "data.current_page", 1);
					this.certificates.showing_string = ObjectPath.get(output, "data.showing_string", null);
					this.certificates.total_page = ObjectPath.get(output, "data.total_page", 0);
				})
				.catch(({ response }) => {
					console.error({ response });
				})
				.finally(() => {
					this.certificateLoading = false;
				});
		},
		downloadFile(row) {
			window.open(row.url, "target");
		},
	},
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [{ text: "Dashboard", disabled: true }]);
		ApiService.get(`dashboard/all`)
			.then(({ data }) => {
				this.dashboardDetail = data;
				// console.log(this.dashboardDetail);
			})
			.catch(({ response }) => {
				console.error({ response });
			}).finally;
		{
			this.pageLoading = false;
		}

		if (
			!this.getPermission("customer::edit") &&
			!this.getPermission("quotation::view") &&
			!this.getPermission("purchase-order::view") &&
			!this.getPermission("task::view") &&
			!this.getPermission("project::view") &&
			!this.getPermission("claim-expense::view")
		) {
			this.showDashboard = false;
			// console.log(showDashboard);
		}
	},
};
</script>
