<template>
	<div class="card card-flush p-6">
		<!--begin::Header-->
		<div class="d-flex justify-space-between">
			<!--begin::Title-->
			<!-- <h3 class=" align-items-start flex-column"> -->
			<div class="mb-2 d-flex align-center font-level-6-bold" style="font-weight: 600; color: #0d47a1">
				Expenses
			</div>
			<!-- <span class="text-gray-400 mt-1 fw-semibold fs-6">1,046 Inbound Calls today</span> -->
			<!-- </h3> -->
			<!-- <v-menu offset-y min-width="100px" tile rounded bottom>
				<template v-slot:activator="{ on, attrs }">
					<v-btn outlined color="grey" dark v-bind="attrs" v-on="on" append-icon="mdi-chevron-down">
						May 23
						<v-icon dark> mdi-chevron-down </v-icon>
					</v-btn>
				</template>
				<v-list elevation>
					<v-list-item
						class="task-list-item cursor-pointer p-1"
						v-for="(item, index) in dateList"
						:key="index"
						@click="updateChart(item)"
						:class="[selectedDate==item.value ? 'blue lighten-5' : '']"
					>
						<v-list-item-title class="pl-2">{{ item.text }}</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu> -->
			<div style="width: 150px" item-text="text" item-value="value">
				<v-select :items="dateList" solo @input="updateChart" v-model="selectedDate"></v-select>
			</div>
		</div>

		<div id="chart">
			<apexchart type="area" height="350" :options="chartOptions" :series="series"></apexchart>
		</div>
	</div>
</template>

<script>
export default {
	name: "task-stats",

	data() {
		return {
			series: [
				{
					name: "Expense",
					data: [12, 52, 38, 45, 19, 23],
				},
			],
			chartOptions: {
				chart: {
					type: "area",
					height: 350,
					zoom: {
						enabled: false,
					},
				},

				dataLabels: {
					enabled: false,

					formatter: function (val) {
						return "$" + val;
					},
					style: {
						fontSize: "12px",
						colors: ["#fff"],
					},
				},
				stroke: {
					// curve: "straight",
					// curve: "smooth",
					show: true,
					curve: "smooth",
					lineCap: "butt",
					width: 3,
					// colors: ["#gcdsch   "],
					dashArray: 0,
				},
				tooltip: {
					y: {
						formatter: function (val) {
							return "$" + val;
						},
					},
				},

				theme: {
					palette: "palette2",
					monochrome: {
						enabled: true,
						color: "#0D47A1",
						shadeTo: "light",
						shadeIntensity: 0.75,
					},
				},
				fill: {
					type: "gradient",
					gradient: {
						shadeIntensity: 1,
						opacityFrom: 0.7,
						opacityTo: 0.9,
						stops: [30, 90, 100],
					},
				},
				xaxis: {
					categories: ["01 Jan", "02 Jan", "03 Jan", "04 Jan", "05 Jan", "06 Jan"],
				},
				yaxis: {
					opposite: true,
				},
				legend: {
					horizontalAlign: "left",
				},
			},
			dateList: ["April 2023", "march 2023", "Feb 2023"],
			selectedDate: null,
		};
	},
	props: {
		dashboardDetail: {
			type: Object,
			default: () => {},
		},
	},
	methods: {
		updateChart(item) {
			let selectedIndex = 0;
			this.selectedDate = item;
			this.dashboardDetail.expenses.statistics.forEach((ele, index) => {
				if (ele.name == item) {
					selectedIndex = index;
				}
			});
			this.series[0].data = this.dashboardDetail.expenses.statistics[selectedIndex].data.map((ele) => {
				return ele.sum_of_amount;
			});
			// console.log(this.series[0].data);
			this.chartOptions = {
				chart: {
					type: "area",
					height: 350,
					zoom: {
						enabled: false,
					},
				},
				dataLabels: {
					enabled: false,
					// off: 50,
					// offsetX: ,
					formatter: function (val) {
						return "$" + val;
					},
					style: {
						fontSize: "12px",
						colors: ["#fff"],
					},
				},
				stroke: {
					// curve: "straight",
					// curve: "smooth",
					show: true,
					curve: "smooth",
					lineCap: "butt",
					width: 3,
					// colors: ["#gcdsch   "],
					dashArray: 0,
				},
				tooltip: {
					y: {
						formatter: function (val) {
							return "$" + val;
						},
					},
				},
				theme: {
					palette: "palette2",
					monochrome: {
						enabled: true,
						color: "#0D47A1",
						shadeTo: "light",
						shadeIntensity: 0.75,
					},
				},
				fill: {
					type: "gradient",
					gradient: {
						shadeIntensity: 1,
						opacityFrom: 0.7,
						opacityTo: 0.9,
						stops: [30, 90, 100],
					},
				},
				xaxis: {
					categories: ["01 Jan", "02 Jan", "03 Jan", "04 Jan", "05 Jan", "06 Jan"],
				},
				yaxis: {
					opposite: true,
				},
				legend: {
					horizontalAlign: "left",
				},
			};
			this.chartOptions.xaxis.categories = this.dashboardDetail.expenses.statistics[
				selectedIndex
			].data.map((ele) => {
				return ele.date_name;
			});
			// console.log(this.chartOptions.xaxis.categories);
		},
	},
	mounted() {
		this.dashboardDetail.expenses.dropdown.forEach((ele) => {
			if (ele.default) {
				this.selectedDate = ele.value;
				this.updateChart(ele.value);
			}
		});
		this.dateList = this.dashboardDetail.expenses.dropdown;
	},
};
</script>
