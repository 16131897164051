<template>
	<div class="card card-flush pb-4">
		<div class="px-4 p-4 dashboard-card-header d-flex justify-space-between">
			<div class="mb-2 d-flex align-center font-level-6-bold" style="font-weight: 600; color: #0d47a1">
				Tasks
			</div>
		</div>
		<div>
			<v-col md="12">
				<v-row>
					<v-col md="12" class="d-flex justify-space-between">
						<v-col
							md="2"
							@click="changeStatus('all')"
							class="box-shadow1 mr-6 cursor-pointer"
							:class="getStatusColor('all')"
						>
							<div class="p-1 text-white">
								<div class="d-flex justify-space-between">
									<span class="font-level-5-bold">All </span>
								</div>
								<div class="d-flex align-items-center justify-space-between mt-4">
									<div class="dashboard-summary-heading">
										{{ dashboardDetail?.task_analysis_graph?.all_task_count }}
									</div>
									<!-- <v-icon size="45" color="white">mdi-account-supervisor</v-icon> -->
									<inline-svg
										style="height: 50px; width: 50px"
										fill="#ffff"
										:src="$assetURL('media/custom-svg/task.svg')"
									/>
								</div>
							</div>
						</v-col>
						<v-col
							md="2"
							@click="changeStatus('completed')"
							class="box-shadow1 mr-6 cursor-pointer"
							:class="getStatusColor('completed')"
						>
							<div class="p-1 text-white">
								<div class="d-flex justify-space-between">
									<span class="font-level-5-bold"> Completed </span>
								</div>
								<div class="d-flex align-items-center justify-space-between mt-4">
									<div class="dashboard-summary-heading">
										{{ dashboardDetail?.task_analysis_graph?.completed_task_count }}
									</div>

									<inline-svg
										style="height: 55px; width: 55px"
										fill="#ffff"
										:src="$assetURL('media/custom-svg/completed.svg')"
									/>
								</div>
							</div>
						</v-col>
						<v-col
							md="2"
							@click="changeStatus('in-progress')"
							class="box-shadow1 mr-6 cursor-pointer"
							:class="getStatusColor('in-progress')"
						>
							<div class="p-1 text-white">
								<div class="d-flex justify-space-between">
									<span class="font-level-5-bold"> In Progress </span>
								</div>
								<div class="d-flex align-items-center justify-space-between mt-4">
									<div class="dashboard-summary-heading">
										{{ dashboardDetail?.task_analysis_graph?.inprogress_task_count }}
									</div>
									<inline-svg
										style="height: 55px; width: 55px"
										fill="#ffff"
										:src="$assetURL('media/custom-svg/In-progress.svg')"
									/>
								</div>
							</div>
						</v-col>
						<v-col
							md="2"
							@click="changeStatus('pending')"
							class="box-shadow1 mr-6 cursor-pointer"
							:class="getStatusColor('pending')"
						>
							<div class="p-1 text-white">
								<div class="d-flex justify-space-between">
									<span class="font-level-5-bold"> Pending </span>
								</div>
								<div class="d-flex align-items-center justify-space-between mt-4">
									<div class="dashboard-summary-heading">
										{{ dashboardDetail?.task_analysis_graph?.pending_task_count }}
									</div>
									<inline-svg
										style="height: 55px; width: 55px"
										fill="#ffff"
										:src="$assetURL('media/custom-svg/pending.svg')"
									/>
								</div>
							</div>
						</v-col>
						<v-col
							md="2"
							@click="changeStatus('overdue')"
							class="box-shadow1 mr-6 cursor-pointer"
							:class="getStatusColor('overdue')"
						>
							<div class="p-1 text-white">
								<div class="d-flex justify-space-between">
									<span class="font-level-5-bold"> Overdue </span>
								</div>
								<div class="d-flex align-items-center justify-space-between mt-4">
									<div class="dashboard-summary-heading">
										{{ dashboardDetail?.task_analysis_graph?.overdue_task_count }}
									</div>
									<inline-svg
										style="height: 55px; width: 55px"
										fill="#ffff"
										:src="$assetURL('media/custom-svg/overdue.svg')"
									/>
								</div>
							</div>
						</v-col>
					</v-col>
				</v-row>
			</v-col>
		</div>
		<div class="taskListing mx-3 mt-3">
			<div style="width: 100%" class="d-flex justify-end px-4">
				<div style="width: 200px">
					<v-select
						filled
						v-model="currentStatus"
						:items="statusList"
						item-text="text"
						text-value="value"
						@change="changeStatus"
					>
						<template v-slot:item="data">
							<template>
								<v-list-item-content style="padding: 0px" dense>
									<v-list-item-title
										class="d-flex justify-space-between"
										style="padding: 0px; font-weight: 500 !important"
										><div>
											{{ data.item.text }}
										</div>

										<div>
											<v-chip small text :color="getStatusColor(data.item.value)" class="mx-2 white--text">{{
												data.item.count
											}}</v-chip>
										</div>
									</v-list-item-title>
								</v-list-item-content>
							</template>
						</template>
					</v-select>
				</div>
			</div>
			<table v-if="!pageLoading" class="table table-row-dashed align-middle gs-0 gy-3 my-0">
				<thead>
					<tr>
						<th class="simple-table-th">#</th>
						<th class="simple-table-th" width="250px">Detail</th>
						<th class="simple-table-th">Status</th>
						<th class="simple-table-th">Project</th>
						<!-- <th class="simple-table-th">Status</th> -->
						<th class="simple-table-th">Team</th>
						<!-- <th class="simple-table-th">Priority</th> -->
						<!-- <th class="simple-table-th">Reminder</th> -->
						<th class="simple-table-th">Date</th>
						<!-- <th max-width="150px" class="simple-table-th">Tags</th> -->
					</tr>
				</thead>
				<tbody v-if="tasksList.length">
					<tr
						@click="goToTask(row)"
						class="cursor-pointer"
						v-for="(row, index) in tasksList"
						:key="index"
					>
						<td class="td_column cursor-default simple-table-td">{{ index + 1 }}</td>
						<td class="td_column cursor-default simple-table-td">
							<div class="flex-wrap">
								<div>
									<span class="fw-500 mr-1">Name: </span>
									<ShowValue class="mr-1" :object="row" object-key="name" label="Task Name"></ShowValue>
								</div>
								<div class="d-flex">
									<span class="fw-500 mr-1">Description: </span>
									<span class="line-clamp-2" style="max-width: 150px">
										<ShowTextAreaValue
											class="mr-1"
											:object="row"
											object-key="description"
											label="Description"
										></ShowTextAreaValue>
									</span>
								</div>
							</div>
						</td>
						<td class="td_column cursor-default simple-table-td">
							<Chip
								class="mt-1"
								tooltip
								tooltip-text="status"
								:text="row.status_value"
								:color="getStatusColor(row.status_value)"
							></Chip>
						</td>
						<td @click.prevent.stop="goToProject(row)" class="td_column cursor-default simple-table-td">
							<Chip
								:text="row.project_barcode"
								tooltip
								tooltipText="project #"
								color="cyan"
								class="cursor-pointer"
							></Chip>
						</td>

						<td class="td_column cursor-default simple-table-td">
							<div class="d-flex align-items-center">
								<template v-if="row.team.length">
									<template v-for="(member, index) in row.team">
										<v-tooltip top content-class="custom-top-tooltip" :key="index + 4">
											<template v-slot:activator="{ on, attrs }">
												<v-avatar
													v-bind="attrs"
													v-on="on"
													v-if="index < 2"
													color="blue lighten-4"
													size="40"
													:style="`margin-left:${
														index > 0 ? '-12px' : ''
													};border:1px solid #0000002e !important`"
												>
													<template v-if="row && row.image">
														<v-img :src="row.image"></v-img>
													</template>
													<span v-else style="font-size: 14px" class="">
														{{ getTwoCharacter(member && member.user_name) }}
													</span>
												</v-avatar>
											</template>
											<span style="font-size: 11px">{{ member.user_name }}</span>
										</v-tooltip>
										<v-chip
											v-if="index == 2"
											:key="index"
											x-small
											label
											color="blue darken-4 white--text"
											class="px-1"
										>
											+{{ row.team.length - 2 }} more
										</v-chip>
									</template>
								</template>
								<template v-else>
									<span>
										<em class="text-muted">No team</em>
									</span>
								</template>
							</div>
						</td>

						<!-- <td class="td_column cursor-default simple-table-td">
							<div class="flex-wrap">
								<div>
									<span class="fw-500 mr-1">Type:</span>
									<span class="fw-400" v-if="row.reminder_type_value">{{ row.reminder_type_value }}</span>
									<em v-else class="text-muted">No Type</em>
								</div>
								<div>
									<span class="fw-500 mr-1">Reminder Start Date:</span>
									<span class="fw-400" v-if="row.reminder_start_date">{{
										formatDate(row.reminder_start_date)
									}}</span>
									<em v-else class="text-muted">No Reminder Start Date</em>
								</div>
								<div>
									<span class="fw-500 mr-1">Reminder End Date:</span>
									<span class="fw-400" v-if="row.reminder_end_date">{{
										formatDate(row.reminder_end_date)
									}}</span>
									<em v-else class="text-muted">No Reminder End Date</em>
								</div>
								<div>
									<span class="fw-500 mr-1">Frequency:</span>
									<span class="fw-400" v-if="row.frequency">{{ row.frequency }}</span>
									<em v-else class="text-muted">No Frequency</em>
								</div>
							</div>
						</td> -->
						<td class="td_column cursor-default simple-table-td">
							<div class="flex-wrap">
								<div>
									<span class="fw-500 mr-1">Start Date:</span>
									<span class="fw-400" v-if="row.expected_start_date">{{
										formatDate(row.expected_start_date)
									}}</span>
									<em v-else class="text-muted">No Start Date</em>
								</div>

								<div>
									<span class="fw-500 mr-1">End Date:</span>
									<span class="fw-400" v-if="row.expected_end_date">{{
										formatDate(row.expected_end_date)
									}}</span>
									<em v-else class="text-muted">No End Date</em>
								</div>
							</div>
						</td>
					</tr>
				</tbody>
				<tfoot v-else>
					<tr>
						<td colspan="11">
							<p class="m-0 row-not-found text-center py-3">
								<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
								Uhh... There are no tasks at the moment.
							</p>
						</td>
					</tr>
				</tfoot>
			</table>
			<div v-else class="mx-20 my-40 text-center">
				<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
			</div>
			<v-row class="my-2 d-flex justify-end">
				<v-col md="6" class="text-right">
					<v-pagination
						color="blue darken-4"
						v-model="currentPage"
						v-if="tasksList.length"
						:length="totalPage"
						total-visible="7"
						v-on:input="getTasksList()"
					></v-pagination>
				</v-col>
			</v-row>
		</div>
	</div>
</template>

<script>
import ShowValue from "@/view/components/ShowValue.vue";
// import { SET_LOCAL_DB } from "@/core/services/store/config.module";
import { mapGetters } from "vuex";
import { SET_ERROR /* SET_MESSAGE */ } from "@/core/services/store/common.module";
import ShowTextAreaValue from "@/view/components/ShowTextAreaValue.vue";
import { getTasks } from "@/core/lib/project.lib";
import Chip from "@/view/components/Chip";
export default {
	name: "task-stats",
	components: {
		ShowValue,
		Chip,
		ShowTextAreaValue,
	},
	props: {
		dashboardDetail: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			currentStatus: "all",
			currentPage: 1,
			totalPage: null,
			pageLoading: false,
			tasksList: [],
			statusList: [
				{
					text: "All",
					value: "all",
				},
				{
					text: "Completed",
					value: "completed",
				},
				{
					text: "In Progress",
					value: "in-progress",
				},
				{
					text: "Pending",
					value: "pending",
				},
				{
					text: "Overdue",
					value: "overdue",
				},
			],
		};
	},
	computed: {
		...mapGetters(["localDB"]),
		priorityText: function () {
			return function name(row) {
				return row.priority === 1 ? "low" : row.priority === 2 ? "medium" : "high";
			};
		},
		priorityColor: function () {
			return function name(row) {
				return row.priority === 1
					? "grey--text lighten-1"
					: row.priority === 2
					? "blue--text"
					: "red--text";
			};
		},
		taskStatusColor: function () {
			return function name(row) {
				return row.status_value === "completed"
					? "green--text"
					: row.status_value === "in-progress"
					? "blue--text"
					: "red--text";
			};
		},
		taskStatusBorder: function () {
			return function name(row) {
				return row.status_value === "completed"
					? "border:1px solid #4CAF50"
					: row.status_value === "in-progress"
					? "border:1px solid #2196F3"
					: "border:1px solid #F44336";
			};
		},
	},
	methods: {
		getTwoCharacter(word) {
			if (word) {
				return word.slice(0, 2).toUpperCase();
			}
			return "";
		},
		goToTask(row) {
			this.$router.push({
				name: `project-detail`,
				params: { uuid: row.project_uuid },
				query: { tab: "task" },
			});
		},
		goToProject(row) {
			this.$router.push({
				name: `project-detail`,
				params: { uuid: row.project_uuid },
			});
		},
		changeStatus(item) {
			this.currentStatus = item;
			this.getTasksList();
		},
		async getTasksList() {
			this.pageLoading = true;
			const _this = this;
			try {
				const { tbody, total_page } = await getTasks({
					page: this.currentPage,
					per_page: 5,
					status: this.currentStatus,
				});
				_this.tasksList = [...tbody];
				_this.totalPage = total_page;
			} catch (error) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				_this.pageLoading = false;
			}
		},
	},
	async mounted() {
		this.statusList = this.statusList.map((ele) => {
			if (ele.value == "all") {
				ele.count = this.dashboardDetail?.task_analysis_graph?.all_task_count;
			} else if (ele.value == "completed") {
				ele.count = this.dashboardDetail?.task_analysis_graph?.completed_task_count;
			} else if (ele.value == "pending") {
				ele.count = this.dashboardDetail?.task_analysis_graph?.pending_task_count;
			} else if (ele.value == "overdue") {
				ele.count = this.dashboardDetail?.task_analysis_graph?.overdue_task_count;
			} else if (ele.value == "in-progress") {
				ele.count = this.dashboardDetail?.task_analysis_graph?.inprogress_task_count;
			}
			return ele;
		});
		this.getTasksList();
	},
};
</script>
